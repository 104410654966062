html {
  box-sizing: border-box;
  height: 100%;
}

body {
  font-family: 'Fira Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  font-family: 'Fira Sans', sans-serif;
}

.notification-container-bottom-center,
.notification-container-bottom-left,
.notification-container-bottom-right,
.notification-container-center,
.notification-container-top-center,
.notification-container-top-left,
.notification-container-top-right {
  width: auto !important;
}

@media print {
  body {
    position: absolute;
    top: 0px;
    left: 0px;
  }
}

*[tabindex='-1'] {
  height: 100%;
}
